<template>
    <div class="home">
        <el-form class="search_box" ref="form" :model="search" label-width="70px" size='medium' :inline="true">
            <el-form-item label="手机号码" label-width="100px">
                <el-input v-model="search.mobile"></el-input>
            </el-form-item>
            <el-form-item label="兑换码状态" label-width="100px">
                <el-select v-model="search.status" style="width: 100%">
                    <el-option v-for="item in statusList" :label='item.name' :value='item.id' :key='item.id'></el-option>
                </el-select>
            </el-form-item>


            <!-- 全部 待发货  待收货  未支付  已完成  已取消 -->
            <el-form-item label-width="0">
                <el-button size='medium' type='primary' @click="serchFn">搜索</el-button>
                <el-button size='medium' type='primary' @click="emptys">重置</el-button>
            </el-form-item>
        </el-form>
        <div>
            <el-button size='medium' type='primary' @click="memberFn()">生成会员兑换码</el-button>
        </div>
        <div class="posr">
            <el-table ref="multipleTableRef" v-loading="loadingShow" :data="tableData" style="width: 100%;margin-top: 20px;"
                :header-cell-style="{ background: '#F7F8FA', color: '#333333' }">
                <el-table-column prop="mobile" label="用户手机号码" align="center">
                </el-table-column>
                <el-table-column prop="code" label="会员兑换码" align="center">
                </el-table-column>
                <el-table-column prop="status" label="使用状态" align="center">
                    <template slot-scope="scope">
                        {{
                            {
                                0: '未使用',
                                1: '已使用'
                            }[scope.row.status]||'-'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="months" label="特权时间(月)" align="center">
                </el-table-column>
                <el-table-column prop="usetime" label="激活时间" align="center">
                </el-table-column>
                <!-- <el-table-column prop="mobile" label="到期时间" align="center">
                </el-table-column> -->
                <el-table-column prop="operator" label="操作人" align="center">
                </el-table-column>
                <!-- <template slot-scope="scope">
                        <el-button type="text" @click="memberFn(scope.row)">奖励</el-button>
                    </template> -->
            </el-table>
            <div class="footer-pagin">
                <el-pagination :current-page.sync="search.page" :page-sizes="pageSizes" :pager-count="5"
                    :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

        <el-dialog title="生成会员兑换码" :visible.sync="memberVisible" @close="closeDialog" :close-on-click-modal="false" width="500px">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="用户手机号码" prop="mobile">
                        <el-input-number v-model="ruleForm.mobile" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="生成特权时间" prop="months">
                        <el-select v-model="ruleForm.months" style="width: 100%">
                            <el-option v-for="item in monthsList" :label='item.name' :value='item.id' :key='item.id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="memberVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>
  
<script>
import { GetCodeAdPage, CreateCode } from '@/service/addMember/addMember.js'
// @ is an alias to /src
import { Message} from 'element-ui'
export default {
    name: 'Home',
    components: {
        
    },
    data() {
        var phoneValidator = (rule, value, callback) => {
            const regex = /^1[3456789]\d{9}$/;//使用此正则即可
            if (!regex.test(value)) {
                        return callback(new Error("请输入正确手机格式"));
            } else {
                return callback();
            }
            };
        return {
            loadingShow: false,
            memberVisible: false,
            ruleForm: {},
            rules: {
                mobile: [
                    { required: true, message: '请填写用户手机号码', trigger: 'blur' },
                    { validator: phoneValidator, trigger: 'blur' }
                ],
                months: [
                    { required: true, message: '请选择生成特权时间', trigger: 'change' },
                ],
            },
            id: '',
            tableData: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            pageSizes: [10, 20, 50, 100],
            total: 0,
            statusList: [{name: '已使用',id: 1},{name: '未使用',id: 0}],
            monthsList: [{name: '3个月',id: 3},{name: '6个月',id: 6},{name: '12个月',id: 12}],
        }
    },
    created() {
        this.gettableList();
    },
    methods: {
        
        
        memberFn() {
            // this.id = row ? row.id : ''
            this.memberVisible = true
        },
        
        closeDialog() {
            this.ruleForm = {}
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                CreateCode({ ...this.ruleForm }).then(res => {
                    this.loadingShow = false;
                    console.log(res)
                    this.memberVisible = false
                    Message.success(`生成成功`)
                    this.gettableList()
                }).catch(err => {
                    console.log(err)
                    Message.error(err)
                    this.loadingShow = false;
                })

            });
        },
        serchFn() {
            this.gettableList();
        },
        emptys() {
            for (const key in this.search) {
                this.search[key] = undefined
            }
            this.search.page = 1
            this.search.pageSize = 10
            this.serchFn()
        },
        gettableList() {
            this.loadingShow = true
            const query = {
                ...this.search
            }

            GetCodeAdPage(query).then(res => {
                this.loadingShow = false;
                console.log(res)
                this.tableData = res.data.list
                this.total = res.data.count
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        // 每页的条数
        handleSizeChange(val) {
            this.search.pageSize = val;
            this.gettableList();
        },
        // 分页
        handleCurrentChange(val) {
            this.search.page = val;
            this.gettableList();
        },
    }
}
</script>
<style lang="less" scoped>
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  